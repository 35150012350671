.account-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    border-radius: 10px;
    padding: 10px 10px;
}

@keyframes spinAndShrink {
    0% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(360deg) scale(0.5);
    }
    100% {
        transform: rotate(720deg) scale(0);
    }
}

.account-container .profile {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.account-container .profile .user-image {
    display: flex;
    color: #bbb;
    font-size: 13rem;
    border-radius: 50%;
    size: 5rem;
    padding-top: 10px;
}
.account-container .profile .user-image.animate {
    animation: spinAndShrink 0.5s forwards;
}


.account-container .profile .logout-icon {
    font-size: 1.25rem;
    color: #ffffff;
    padding-top: 10px;
    cursor: pointer;
}
.account-container .profile .logout-icon:hover {
    color: #c0bfbf;
}

.info {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 10px;
}

.name {
    font-size: 1.3rem;
    font-weight: bold;
}

.email {
    font-size: 1.0rem;
    color: #888;
}

.level {
    display: flex;
    flex-direction: column;
}

.account-button {
    width: 20rem;
    height: 100%;
    padding: 10px 20px;
    background-color: #35B11B;
    color: #fff;
    border: none;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
}
.account-button:hover {
    background-color: #49CC29;
}
.withdrawal-button {
    width: 20rem;
    height: 100%;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #D53F3B;
    color: #fff;
    border: none;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
}
.withdrawal-button:hover {
    background-color: #FF5A52;
}

/* Full-screen overlay styling */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999; /* Ensure it covers the content */
    opacity: 0;
    transform: scale(1);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Paper crumple animation */
@keyframes crumple {
    0% {
        transform: scale(1) rotate(0deg);
        opacity: 1;
    }
    50% {
        transform: scale(1.2) rotate(30deg);
        opacity: 0.8;
    }
    100% {
        transform: scale(0) rotate(360deg);
        opacity: 0;
    }
}

.crumple {
    animation: crumple 0.5s forwards;
}

/* Full-screen overlay styling */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999; /* Ensure it covers the content */
    opacity: 0;
    transform: scale(1);
    pointer-events: none; /* Allow clicks to pass through */
}
