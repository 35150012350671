.chat-header {
    display: flex;
    justify-content: space-between;
    background-color: #415A77;
    align-items: center;
    width: auto;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 10px;
    margin: 5px;
}
.chat-header-main {
    display: flex;
    align-items: center;
    width: auto;
}

.chat-mode {
    background-color: #dc9b11;
    color: #CCCCCC;
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: 700;
    margin-right: 10px;
}

.chat-model {
    background-color: #9463ee;
    color: #CCCCCC;
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: 700;
    margin-right: 10px;
}

.chat-roomname {
    display: flex;
    margin-left: 15px;
    color: #CCCCCC;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
}

.chat-room-input {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    outline: none;
    border-bottom: 1px solid #555;
    margin-right: 10px; /* Ensures there is space before the icon */
}

.chat-roomname .button {
    margin-left: 1px;
}


.dropdown {
    position: relative;
    cursor: pointer;
}

.model-dropdown {
    position: relative;
    display: flex;
    flex-direction: row;
}

.dropdown-menu {
    position: absolute;
    top: 25px; /* Adjust this value to position the dropdown correctly */
    right: 0;
    background-color: #444;
    border: 1px solid #555;
    border-radius: 4px;
    overflow: hidden;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.dropdown-menu button {
    background: none;
    border: none;
    color: white;
    padding: 10px;
    text-align: left;
    cursor: pointer;
}

.dropdown-menu button:hover {
    background-color: #555;
}

.batch-button {
    display: flex;
    justify-content: space-between;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

.first-button {
    height: 100%;
    min-width: 80px;
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: 700;
    color: white;
    background-color: #2E88CC;
    cursor: pointer;
}

.first-button:hover {
    background-color: #3b99d8;
}

.irst-button.enabled {
    background-color: #2E88CC;
    animation: shake 0.5s ease; 
}

.first-button.disabled {
    background-color: #CCCCCC;
    cursor: not-allowed;
}

.second-button {
    background-color: #D53F3B;
}
.second-button:hover {
    background-color: #e64a45;
}