.messages {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.message-container {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin: 15px 30px 0 30px;
}

.message-container.bot {
    display: flex;
    align-items: center;
    background-color: #282B40;
    padding-left: 2rem;
    border-radius: 15px;
    /* 기본 왼쪽 정렬 */
}

.message {
    padding: 1rem;
    border-radius: 5px;
    max-width: 70%;
    cursor: pointer;
}

.message.user {
    align-self: flex-end;
    /* 오른쪽 정렬 */
    background-color: #636a9f;
    color: #CCCCCC;
    margin-left: auto;
    margin-top: 1vh;
    margin-bottom: 1vh;
    /* 왼쪽 여백 자동 설정 */
}

.message.bot {
    color: #CCCCCC;
    margin-right: auto;
    /* 오른쪽 여백 자동 설정 */
}

.profile-image {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}

.message-timestamp {
    font-size: 0.8rem;
    color: #888;
    align-self: flex-end;
}

.loading-cursor {
    cursor: wait;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1); 
    border-left: 4px solid #3498db; 
    border-radius: 50%;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}