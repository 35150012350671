.chat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 10px;
    padding: 10px 10px;
}

.title {
    width: 100%;
    display: flex;
    padding-bottom: 1vh;
    border-bottom: 7px double #ddd;
}

.history-icon {
    display: flex;
    font-size: 1.5vh;
    color: #CCCCCC;
    padding-left: 5%;
}

.title-name {
    display: flex;
    font-size: 1.5rem;
    font-weight: 700;
    padding-left: 10px;
    color: #CCCCCC;
}

.chat-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1; 
}

.chat-list .chat-item {
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    cursor: pointer;

    color: #CCCCCC;
    border-radius: 5px; /* Rounded corners */
    text-overflow: ellipsis;
    position: relative; 
}

.chat-item-input {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    outline: none;
    border-bottom: 1px solid #555;
    margin-right: 10px; /* Ensures there is space before the icon */
}

.chat-item:hover {
    font-weight: bold;
    background-color: #282B40;
}
.chat-item-icon {
    visibility: hidden; /* Initially hide ellipsis icon */
    position: absolute;
    right: 8px; /* Adjust position as needed */
    top: 50%;
    transform: translateY(-50%);
}

.chat-item:hover .chat-item-icon {
    visibility: visible; /* Show ellipsis icon on hover */
}

.chat-date {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 5px;
}

.delete-icon {
    color: #D53F3B;
}
.delete-icon:hover {
    color: #FF5A52; /* Change color on hover */
}

.edit-icon {
    margin-right: 5px;
    color: #2E88CC;
}
.edit-icon:hover {
    color: #3b99d8; /* Change color on hover */
}

.level {
    display: flex;
    padding-top: 1vh;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

.chat-button {
    width: 20rem;
    height: 100%;
    padding: 10px 20px;
    background-color: #2E88CC;
    color: #fff;
    border: none;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease; 
}
.chat-button:hover {
    animation: shake 0.5s ease; 
    background-color: #3b99d8;
}
