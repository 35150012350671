/* ChatWindow.css */
.chat-batch-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    margin: 15px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
}

.chat-batch-windows-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    height: 100%;
    overflow: hidden;
}

.chat-window {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 5px;
    overflow: hidden;
    background-color: #415A77;
    background-image: url('/public/Arti-removebg.png');
    background-size: 40vh;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.scroll-to-bottom-button {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    border: none;
    border-radius: 50%;
    background-color: #778DA9;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scroll-to-bottom-button.hidden {
    display: none;
}
