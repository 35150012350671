.plugin-container {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding: 10px 10px;
    border-radius: 10px;
}

.plugin-title {
    width: 100%;
    display: flex;
    padding-bottom: 1vh;
    border-bottom: 7px double #ddd;
}

.title-name {
    align-items: center;
    display: flex;
    padding-left: 10px;
}

.gender-selection {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
}

.gender-label {
    display: flex;
    margin-right: 10px;
}

.gender-label input[type="radio"] {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.gender-text {
    font-weight: bold;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}


.speed-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.speed-text {
    font-size: 18px;
    margin-bottom: 10px; /* Add space below the text */
}

.speed-slider {
    width: 300px;
    -webkit-appearance: none; /* Remove default styling */
    appearance: none;
    height: 8px; /* Set height of the slider */
    background: #ccc; /* Background color of the slider */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set opacity for visual appearance */
    transition: opacity .15s ease-in-out; /* Transition for smooth hover effect */
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 5px;
    cursor: pointer; /* Change cursor on hover */
}

.speed-slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default styling */
    appearance: none;
    width: 16px; /* Set width of the thumb */
    height: 16px; /* Set height of the thumb */
    background: #2E88CC; /* Color of the thumb */
    border-radius: 50%; /* Rounded edges for the thumb */
    cursor: pointer; /* Change cursor on hover */
}

.speed-slider::-moz-range-thumb {
    width: 16px; /* Set width of the thumb for Firefox */
    height: 16px; /* Set height of the thumb for Firefox */
    background: #3b99d8; /* Color of the thumb for Firefox */
    border-radius: 50%; /* Rounded edges for the thumb for Firefox */
    cursor: pointer; /* Change cursor on hover for Firefox */
}