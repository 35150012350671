/* src/App.css */
.App {
  display: flex;
  flex-direction: column;
  background-color: #0D1B2A;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.header {
  flex-shrink: 0;
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  /* Prevent scrolling */
}