.sample-questions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust minmax value as needed */
    gap: 10px; /* Adds spacing between grid items */
    margin: 10px;
}

.sample-question-btn {
    color: white;
    background-color: rgba(106, 141, 172, 0.8); /* Semi-transparent background color */
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.sample-question-btn:hover {
    background-color: rgba(106, 141, 172, 0.6); /* Semi-transparent hover color */
}
