.input-area {
    align-items: center;
    display: flex;
    padding: 10px 10px;
}

.input-area input {
    width: 100vw;
    min-width: 300px;
    height: 20px;
    padding: 10px 10px 10px 20px;
    border: 1px solid #4D4E50;
    background-color: #0D1B2A;
    color: #CCCCCC;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 15px;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

.input-area button.first-button {
    height: 100%;
    width: 5vw;
    min-width: 80px;
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: 700;
    color: white;
    background-color: #2E88CC;
    cursor: pointer;
}
.input-area button.first-button:hover {
    background-color: #3b99d8;
}

.input-area button.first-button.enabled {
    background-color: #2E88CC;
    animation: shake 0.5s ease; 
}

.input-area button.first-button.disabled {
    background-color: #CCCCCC;
    cursor: not-allowed;
}

.input-area button.second-button {
    background-color: #D53F3B;
}
.input-area button.second-button:hover {
    background-color: #e64a45;
}
