.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #444;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    color: white;
}

.modal-content button {
    margin: 5px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: #555;
    color: white;
}

.modal-content button:hover {
    background-color: #666;
}