.sidebar {
    width: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    color: white;
    padding: 10px;
    box-sizing: border-box;
    transition: width 0.3s ease;
    position: relative;
}

.sidebar.folded {
    width: 0rem; /* Width when folded */
}

.toggle-btn {
    position: absolute;
    top: 50%;
    right: -10px; /* Position the button outside of the sidebar */
    background: #333;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    size: 30px;
    font-size: 20px;
    color: white;
    transition: right 0.3s ease;
}

.sidebar.folded .toggle-btn {
    right: -10px; /* Adjust this position when folded */
}

.container-account-layout {
    display: flex;
    width: 100%;
    flex: 2;
    padding: 5px;
    transition: opacity 0.3s ease;
}

.container-chatlist-layout {
    display: flex;
    width: 100%;
    flex: 7;
    padding: 5px;
    transition: opacity 0.3s ease;
}

.container-plugin-layout {
    display: flex;
    width: 100%;
    flex: 1;
    padding: 5px;
    transition: opacity 0.3s ease;
}

.sidebar.folded .container-account-layout,
.sidebar.folded .container-chatlist-layout,
.sidebar.folded .container-plugin-layout {
    opacity: 0;
    pointer-events: none; /* Prevent interaction when folded */
}

.sidebar h2 {
    margin-top: 0;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    margin: 15px 0;
}